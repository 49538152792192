@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?48504914');
  src: url('../font/fontello.eot?48504914#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?48504914') format('woff2'),
       url('../font/fontello.woff?48504914') format('woff'),
       url('../font/fontello.ttf?48504914') format('truetype'),
       url('../font/fontello.svg?48504914#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?48504914#fontello') format('svg');
  }
}
*/
[class^="acc-"]:before, [class*=" acc-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.acc-trash:before { content: '\e800'; } /* '' */
.acc-pencil:before { content: '\e801'; } /* '' */
.acc-plus:before { content: '\e802'; } /* '' */
.acc-eye:before { content: '\e803'; } /* '' */
.acc-arrows-cw:before { content: '\e804'; } /* '' */
.acc-upload:before { content: '\e805'; } /* '' */
.acc-download:before { content: '\e806'; } /* '' */
.acc-print:before { content: '\e807'; } /* '' */
.acc-cog-outline:before { content: '\e808'; } /* '' */
.acc-users:before { content: '\e809'; } /* '' */
.acc-back-in-time:before { content: '\e80a'; } /* '' */
.acc-gauge:before { content: '\e80b'; } /* '' */
.acc-money-1:before { content: '\e80c'; } /* '' */
.acc-wrench-outline:before { content: '\e80d'; } /* '' */
.acc-wallet:before { content: '\e80e'; } /* '' */
.acc-user-1:before { content: '\e80f'; } /* '' */
.acc-users-outline:before { content: '\e810'; } /* '' */
.acc-exchange:before { content: '\e811'; } /* '' */
.acc-logout:before { content: '\e812'; } /* '' */
.acc-ok-circled:before { content: '\e813'; } /* '' */
.acc-flow-branch:before { content: '\e814'; } /* '' */
.acc-bank-01:before { content: '\e815'; } /* '' */
.acc-down-open-big:before { content: '\e816'; } /* '' */
.acc-up-open-big:before { content: '\e817'; } /* '' */
.acc-right-open-big:before { content: '\e818'; } /* '' */
.acc-left-open-big:before { content: '\e819'; } /* '' */
.acc-iconmonstr-customer-10:before { content: '\e81a'; } /* '' */
.acc-bank-13:before { content: '\e81b'; } /* '' */
.acc-bank-26:before { content: '\e81c'; } /* '' */
.acc-recurring-invoice:before { content: '\e81d'; } /* '' */
.acc-reports:before { content: '\e81e'; } /* '' */
.acc-vendor:before { content: '\e81f'; } /* '' */
.acc-customer:before { content: '\e820'; } /* '' */
.acc-recurring-bills:before { content: '\e821'; } /* '' */
.acc-bills:before { content: '\e822'; } /* '' */
.acc-product:before { content: '\e823'; } /* '' */
.acc-estimate:before { content: '\e824'; } /* '' */
.acc-invoice:before { content: '\e825'; } /* '' */
.acc-taxes:before { content: '\e826'; } /* '' */
.acc-right-open:before { content: '\e827'; } /* '' */
.acc-spin3:before { content: '\e832'; } /* '' */
.acc-spin4:before { content: '\e834'; } /* '' */
.acc-user:before { content: '\f061'; } /* '' */
.acc-docs:before { content: '\f0c5'; } /* '' */
.acc-table:before { content: '\f0ce'; } /* '' */
.acc-money:before { content: '\f0d6'; } /* '' */
.acc-mail-alt:before { content: '\f0e0'; } /* '' */
.acc-doc-text:before { content: '\f0f6'; } /* '' */
.acc-angle-circled-right:before { content: '\f138'; } /* '' */
.acc-bank:before { content: '\f19c'; } /* '' */
.acc-bell-off-empty:before { content: '\f1f7'; } /* '' */
.acc-low-vision:before { content: '\f2a8'; } /* '' */
.acc-user-o:before { content: '\f2c0'; } /* '' */
