.font-size-sm {
    font-size: $font-size-sm;
}

.font-size-base {
    font-size: $font-size-base;
}

.font-size-lg {
    font-size: $font-size-lg;
}

.cursor-pointer {
    cursor: pointer;
}

.fw-semibold {
    font-weight: 500;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-repeat-x {
    background-repeat: repeat-x;
}

.bg-repeat-y {
    background-repeat: repeat-y;
}

.bg-size-cover {
    background-size: cover;
}

.bg-size-contain {
    background-size: contain;
}