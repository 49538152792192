.header-nav {
    background: $primary;
    border-bottom: 1px solid $border-color;
    position: fixed;
    top: 0;
    right:0;
    width: 100%;
    z-index: 999;
    display: flex;
    height: $header-nav-height;

    &.layout-vertical {
    
        @include screen-laptop-only {
            width: calc(100% - #{$side-nav-width-laptop})
        }

        @include screen-laptop-above {
            width: calc(100% - #{$side-nav-width})
        }

        &.is-collapse {
            @include screen-tablet-above {
                width: calc(100% - #{$side-nav-collapse-width})
            }
        }
    }

    .header-nav-wrap {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding: 0 0.9375rem;

        .header-nav-left,
        .header-nav-right {
            list-style-type: none;
            padding-left: 0px;
            margin-bottom: 0px;
            align-items: stretch;
            display: flex;
        }

        .header-nav-left {
            margin-left: 10px;
        }

        .header-nav-right {
            margin-right: 10px;
        }

        .header-nav-item {
            text-decoration: none;
            color: $body-color;
            display: flex;
            align-items: center;
            position: relative;

            .header-nav-item-select {
                display: flex;
                align-items: center;
                height: 100%;
            }

            .toggle-wrapper {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 0 0.65rem;
                cursor: pointer;
            }

            .nav-icon {
                font-size: 1.25rem;
            }

            &:last-child {
                .header-nav-item-select {
                    padding-right: 0;
                }
            }
        }

        .desktop-toggle {
            @include screen-tablet {
                display: none;
            }
        }

        .mobile-toggle {
            @include screen-tablet-above {
                display: none;
            }
        }
    }

    &.header-folded {

        .logo {
            width: $side-nav-folded;
        }
    }

    &.header-text-light {
        .header-nav-wrap {
            .header-nav-item {
                color: $white;
            }
        }
    }
}

.pop-notification {
    width: 350px;
}