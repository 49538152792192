.avatar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: $white;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: $avatar-default-bg;
    width: $avatar-base-size;
    height: $avatar-base-size;
    line-height: $avatar-base-size;
    border-radius: $avatar-circle-radius;

    &.avatar-icon {
        font-size: 18px;
    }

    &.avatar-image {
        background: transparent;
    }

    &.avatar-lg {
        width: $avatar-lg-size;
        height: $avatar-lg-size;
        line-height: $avatar-lg-size;
    }

    &.avatar-sm {
        width: $avatar-sm-size;
        height: $avatar-sm-size;
        line-height: $avatar-sm-size;
    }

    &.avatar-square {
        border-radius: $avatar-square-radius;
    }

    & > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.avatar-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center;
}
