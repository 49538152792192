@import './assets/scss/app.scss';
@import './assets/css/fontello.css';
// @import "~bootstrap/scss/bootstrap";
// @import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~billboard.js/dist/billboard.min.css';
@import 'assets/css/fontello.css';

$header-color: #fff;
$sidebar-color: #1e91d1;
$sidebar-highlight-color: #edd771;
$primary-color: #1e91d1;
$secondary-color: #f2f9f9;
$main-background: #f1f1f1;
$btn-primary-border: darken($primary-color, 10);
$isometric-box-shadow: 3px 3px 6px #cecece, -3px -3px 46px #f2f2f2;
$isometric-background-color: #e0e0e0;
$shadow: 0 4px 5px 0 rgba(50, 50, 50, 0.75);
:root {
  --primary-invoice-color: #efefef;
}

.material,
.datalytics-datatable {
  .datatable-body {
    .datatable-body-row {
      background-color: #fff;

      .datatable-body-cell {
        font-size: 0.85rem !important;
      }
    }
  }

  .datatable-header-cell-label {
    font-weight: bold;
    font-size: 0.85rem;
    line-height: 1.5;
    color: #000;
  }

  .datatable {
    border-radius: 10px !important;
    box-shadow: 0 1px 2px 0 #e5e7ea !important;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 1rem;
  }

  .datatable .table {
    width: 1684px !important;
    margin-bottom: 0 !important;
  }

  .datatable-header {
    font-weight: bold;
    background-color: white !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px 10px 0 0;
    height: 35px !important;
  }

  .datatable-footer {
    padding-left: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #fff !important;
    border-radius: 0 0 10px 10px;
  }
}
.ngx-datatable {
  overflow: visible !important;
  .visible{
    padding: 0px;
  }
}

// .ngx-datatable .datatable-body-cell,
// .ngx-datatable .datatable-header-cell {
//   width:98% !important;
// }

//Dashboard
.card-title {
  font-weight: bolder;
}

p {
  color: #adadad;
}

.tx-center {
  text-align: center !important;
}

.fill {
  display: table;
  border-collapse: separate;
  border-spacing: 1rem;
  width: 100%;

  .card {
    display: table-cell !important;
    border-radius: 10px !important;
    padding: 1.25rem;

    border-radius: 8px;
    background: $white;
    box-shadow: $isometric-box-shadow;
    //

    // margin: .2rem;
    .empty-body {
      min-height: 100%;
      vertical-align: middle;
    }
  }
}
datalytics-report-filters {
  .filter-bar {
    background-color: #ffffff00 !important;
    padding: 5px 0px !important;
    .card-header{
      display: none;
    }
    .card-body{
      padding-bottom: .5rem !important;
    }
  }
  .text-right {
    text-align: right !important;
  }
  
  .btn-primary {
    margin-right: 5px;
    text-align: right;
  }
  .card.mb-4 {
    margin-bottom: 1rem;
  }
}
.btn{
  box-shadow: $shadow !important;
  :hover{
    // background-color: darken( $amount:10);
  }
}
.error {
  color: $danger;
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.btn-primary {
  // margin-right: 0.5rem;
  // border-radius: 10px;
  background: $primary;
  // box-shadow:  10px -10px 15px #cacaca,
  //            -10px 10px 15px #f6f6f6;
  // box-shadow: 10px -10px 15px #cacaca, -10px 10px 15px #f6f6f6;
}
.btn-danger {
  margin-right: 0.5rem;
  // border-radius: 10px;
  background: $danger;
  // box-shadow: 10px -10px 15px #cacaca, -10px 10px 15px #f6f6f6;
}
ngb-modal-backdrop {
  z-index: 1050 !important;
}

.status {
  $draft: #aec6cf;
  $approved: #c7d3dc;
  $sent: #77dd77;
  $viewed: #ffe5b4;
  $partiallypaid: #eee991;
  $closed: #adf0cc;
  $overdue: #ff6961;
  // margin: 5px;
  // padding:0px 2px;
  // white-space: nowrap;
  // border-radius: 5px;
  // border: 1px;
  font-size: 0.85rem;
  font-weight: bold;
  text-align: center;

  &.draft {
    background-color: $draft !important;
    color: #485153 !important;
    border-color: darken($draft, 25);
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23485153' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  &.unsent {
    background-color: $approved !important;
    color: #395260 !important;
    border-color: darken($approved, 25);
  }

  &.sent,
  &.approved {
    background-color: $sent !important;
    color: #fff !important;
    border-color: darken($sent, 25);
  }

  &.viewed {
    background-color: $viewed !important;
    color: #fff !important;
    border-color: darken($viewed, 25);
  }

  &.partial {
    background-color: $partiallypaid !important;
    color: #686307 !important;
    border-color: darken($partiallypaid, 25);
  }

  &.closed,
  &.paid {
    background-color: $closed !important;
    color: #14713f !important;
    border-color: darken($closed, 25);
  }

  &.overdue {
    background-color: $overdue !important;
    color: #fff !important;
    border-color: darken($overdue, 25);
  }

  &.received {
    background-color: $overdue !important;
    color: #fff !important;
    border-color: darken($overdue, 25);
  }
}
// .filter-bar {
//   padding: 0px !important;
//   background-color: $main-background !important;
//   border: 0px !important;

//   div > div > {
//     .card {
//       // background-color: $main-background !important;
//       border: 0px;
//       // box-shadow: none !important;
//       // border-radius: 0px;

//       .card-header {
//         // background-color: $main-background !important;
//         // border: 0px;
//         // box-shadow: none;
//         // border-radius: 0px;

//         .row {
//           .text-right {
//             vertical-align: middle !important;
//           }
//         }
//       }

//       .card-body {
//         // background-color: $main-background !important;
//         padding: 5px;
//         // border: 0px;
//         // box-shadow: none;
//         // border-radius: 0px;
//       }

//       .card-footer {
//         // background-color: $main-background !important;
//         // border: 0px;
//         // box-shadow: none;
//         // border-radius: 0px;

//         div:first-child {
//           // float: left;
//         }
//       }
//     }
//   }
// }
.required > label:after {
  content: ' *';
  color: #da4343;
}

label.error {
  color: #da4343;
}
.table {
  width: 99% !important;
}
.btn-success {
  float: right;
}
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
button[name='filterpaste'] {
  display: none;
}
.btn-group:not(.dropdown) {
  font-size: 0.8rem;

  .btn:not(.dropdown-toggle-split) {
    margin-right: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: $primary;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.my-drop-zone {
  border: 3px dotted #d3d3d3;
}
.p-3 {
  padding: 1rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
btn-info {
  color: #fff;
}
.btn-info:hover {
  background-color: $dark-primary;
  border-color: $dark-primary;
}
.btn-info,
.nav-pills .nav-link.active {
  background-color: $primary;
  border-color: $primary;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.well {
  width: 20rem;
}
.tabbed {
  .nav-tabs {
    li.nav-item > .nav-link.active {
      border-radius: 0.5rem 0.5rem 0px 0px;
      box-shadow: $shadow;
      background-color: $primary;
    }
  }
  // .tab-content > .active {
  //   border: 1px solid $primary;
  // }
}
.select-heading,
.select-font {
  display: none !important;
}

.nav-logo {
  background-color: $primary !important;
}
.header-nav .header-nav-wrap {
  background-color: $primary;
}
.white {
  color: $white;
}
.fb-btn {
  background-color: $white;
  border:1px solid $gray-400;
  border-radius: 0.5rem;
  padding:0.5rem
}
.hab{
  margin-left: auto;
  button{
    margin-right: 0.5rem;
  }
}
.row{
  padding: 0.5rem;
}
